<template>
  <div class="wzdx">
    <el-row class="wzdx-el-row">
      <el-col
        :span="5"
        class="wzdx-el-col"
      >
        <el-tree
          accordion
          :data="lcList"
          node-key="id"
          :props="defaultProps"
          :default-expanded-keys="[1]"
          class=" wzdx-lcWapper"
          @node-click="handleNodeClick"
        />
      </el-col>
      <el-col
        :span="14"
        class="wzdx-el-col"
      >
        <DxfViewer
          ref="viewer"
          :dxf-url="dxfUrl"
          :fonts="fonts"
          @dxf-loaded="_OnLoaded"
          @dxf-cleared="_OnCleared"
          @dxf-message="_OnMessage"
        />
      </el-col>
      <el-col
        :span="5"
        class="wzdx-el-col"
      >
        <LayersList
          :layers="layers"
          @toggleLayer="_OnToggleLayer"
          @toggleAll="_OnToggleAll"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainFont from '@/features/cad/fonts/Roboto-LightItalic.ttf';
import aux1Font from '@/features/cad/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf';
import aux2Font from '@/features/cad/fonts/HanaMinA.ttf';
import aux3Font from '@/features/cad/fonts/NanumGothic-Regular.ttf';
import DxfViewer from './DxfViewer.vue';
import LayersList from './LayersList.vue';
import { DxfViewer as _DxfViewer } from 'dxf-viewer';
import Vue from 'vue';

export default {
  name: 'Wzdx',
  components: { DxfViewer, LayersList },

  data () {
    return {
      // 楼层数据
      lcList: [{
        id: 0,
        label: '广州物资大夏',
        children: [
          {
            id: 1,
            label: '消防电',
            children: [
              { id: '1-1', label: '首层' },
              { id: '1-2', label: '二层' },
              { id: '1-3', label: '三层' },
              { id: '1-4', label: '四层' },
              { id: '1-5', label: '五层' },
              { id: '1-6', label: '六层' },
              { id: '1-7', label: '七层' },
              { id: '1-8', label: '八层' },
              { id: '1-9', label: '九层' },
              { id: '1-10', label: '十层' },
              { id: '1-11', label: '十一层' },
              { id: '1-12', label: '十二层' },
              { id: '1-13', label: '十三层' },
              { id: '1-14', label: '十四层' },
              { id: '1-15', label: '十五层' },
              { id: '1-16', label: '十六层' },
              { id: '1-17', label: '十七层' },
              { id: '1-18', label: '十八层' },
              { id: '1-19', label: '十九层' },
              { id: '1-20', label: '二十层' },
              { id: '1-21', label: '二十一层' },
              { id: '1-22', label: '二十二层' },
              { id: '1-23', label: '天面层' }
            ]
          },
          {
            id: 2,
            label: '消防暖通',
            children: [
              { id: '2-1', label: '首层' },
              { id: '2-2', label: '二层' },
              { id: '2-3', label: '三层' },
              { id: '2-4', label: '四层' },
              { id: '2-5', label: '五层' },
              { id: '2-6', label: '六层' },
              { id: '2-7', label: '七层' },
              { id: '2-8', label: '八层' },
              { id: '2-9', label: '九层' },
              { id: '2-10', label: '十层' },
              { id: '2-11', label: '十一层' },
              { id: '2-12', label: '十二层' },
              { id: '2-13', label: '十三层' },
              { id: '2-14', label: '十四层' },
              { id: '2-15', label: '十五层' },
              { id: '2-16', label: '十六层' },
              { id: '2-17', label: '十七层' },
              { id: '2-18', label: '十八层' },
              { id: '2-19', label: '十九层' },
              { id: '2-20', label: '二十层' },
              { id: '2-21', label: '二十一层' },
              { id: '2-22', label: '二十二层' },
              { id: '2-23', label: '天面层' }
            ]
          },
          {
            id: 3,
            label: '消防水',
            children: [
              { id: '3-1', label: '首层' },
              { id: '3-2', label: '二层' },
              { id: '3-3', label: '三层' },
              { id: '3-4', label: '四层' },
              { id: '3-5', label: '五层' },
              { id: '3-6', label: '六层' },
              { id: '3-7', label: '七层' },
              { id: '3-8', label: '八层' },
              { id: '3-9', label: '九层' },
              { id: '3-10', label: '十层' },
              { id: '3-11', label: '十一层' },
              { id: '3-12', label: '十二层' },
              { id: '3-13', label: '十三层' },
              { id: '3-14', label: '十四层' },
              { id: '3-15', label: '十五层' },
              { id: '3-16', label: '十六层' },
              { id: '3-17', label: '十七层' },
              { id: '3-18', label: '十八层' },
              { id: '3-19', label: '十九层' },
              { id: '3-20', label: '二十层' },
              { id: '3-21', label: '二十一层' },
              { id: '3-22', label: '二十二层' },
              { id: '3-23', label: '天面层' }
            ]
          },
          {
            id: 4,
            label: '装修图',
            children: [
              { id: '4-1', label: '首层' },
              { id: '4-2', label: '二层' },
              { id: '4-3', label: '三层' },
              { id: '4-4', label: '四层' },
              { id: '4-5', label: '五层' },
              { id: '4-6', label: '六层' },
              { id: '4-7', label: '七层' },
              { id: '4-8', label: '八层' },
              { id: '4-9', label: '九层' },
              { id: '4-10', label: '十层' },
              { id: '4-11', label: '十一层' },
              { id: '4-12', label: '十二层' },
              { id: '4-13', label: '十三层' },
              { id: '4-14', label: '十四层' },
              { id: '4-15', label: '十五层' },
              { id: '4-16', label: '十六层' },
              { id: '4-17', label: '十七层' },
              { id: '4-18', label: '十八层' },
              { id: '4-19', label: '十九层' },
              { id: '4-20', label: '二十层' },
              { id: '4-21', label: '二十一层' },
              { id: '4-22', label: '二十二层' },
              { id: '4-23', label: '天面层' }
            ]
          }
        ]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dxfUrl: null,
      layers: null
    };
  },
  methods: {
    handleNodeClick (data) {
      if (data.id === 0) {
        this.dxfUrl = null;
        return;
      }
      this.dxfUrl = `/3DModel/cad/wzdx/${data.id}.dxf`;
    },
    _OnLoaded () {
      const layers = this.$refs.viewer.GetViewer().GetLayers();
      layers.forEach(lyr => Vue.set(lyr, 'isVisible', true));
      this.layers = layers;
    },

    _OnCleared () {
      this.layers = null;
    },

    _OnToggleLayer (layer, newState) {
      layer.isVisible = newState;
      this.$refs.viewer.GetViewer().ShowLayer(layer.name, newState);
    },

    _OnToggleAll (newState) {
      if (this.layers) {
        for (const layer of this.layers) {
          if (layer.isVisible !== newState) {
            this._OnToggleLayer(layer, newState);
          }
        }
      }
    },

    _OnMessage (e) {
      let type = 'info';
      switch (e.detail.level) {
        case _DxfViewer.MessageLevel.WARN:
          type = 'warning';
          break;
        case _DxfViewer.MessageLevel.ERROR:
          type = 'error';
          break;
      }
      this.$message({
        type: type,
        showClose: true,
        message: e.detail.message
      });
    }
  },
  created () {
    this.fonts = [mainFont, aux1Font, aux2Font, aux3Font];
  }
};
</script>

<style lang="scss" scoped>

.wzdx {
  width: 100%;
  height: 100%;
}

.wzdx-el-row {
  height: 100%;
}

.wzdx-lcWapper {
  background-color: rgb(246 246 246);
}

.wzdx-el-col {
  height: 100%;
  overflow: auto;
}
</style>
