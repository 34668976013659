<template>
  <div>
    <div class="wzdx-layer-title">
      图层
    </div>
    <el-row
      class="wzdx-layer-row"
    >
      <el-checkbox
        v-if="layers !== null"
        :value="showAll"
        @change="_ToggleAll"
      >
        所有图层
      </el-checkbox>
    </el-row>

    <el-row
      v-for="layer in layers"
      :key="layer.name"
      class="wzdx-layer-row"
    >
      <el-col :span="2" />
      <el-col :span="4">
        <el-tag
          :color="_GetCssColor(layer.color)"
          effect="light"
          size="mini"
          class="wzdx-layer-color"
        />
      </el-col>
      <el-col :span="16">
        <el-checkbox
          :label="layer.name"
          :value="layer.isVisible"
          @change="e => _ToggleLayer(layer, e)"
        >
          {{ layer.name }}
        </el-checkbox>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'LayersList',

  props: {
    layers: {
      /* Expecting array of {name: string, color: number, isVisible: boolean} */
      type: Array,
      default: null
    }
  },

  data () {
    return {
      showAll: true
    };
  },

  methods: {
    _ToggleLayer (layer, newState) {
      this.$emit('toggleLayer', layer, newState);
      this.showAll = this.layers.filter(item => { return !item.isVisible; }).length === 0;
    },

    _ToggleAll (newState) {
      this.showAll = newState;
      this.$emit('toggleAll', newState);
    },

    _GetCssColor (value) {
      let s = value.toString(16);
      while (s.length < 6) {
        s = '0' + s;
      }
      return '#' + s;
    }
  },

  watch: {
    layers () {
      this.showAll = true;
    }
  }
};

</script>

<style lang="scss" scoped>
.wzdx-layer-title{
  padding: 16px;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,微软雅黑,Arial,sans-serif ;
  font-size: 14px;
  color: #757575;
}

.wzdx-layer-row{
  height: 30px;
  padding-left: 10px;
  line-height: 30px;
}

.wzdx-layer-color{
  width: 20px;
  height: 18px;
}
</style>
