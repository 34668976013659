<template>
  <div
    ref="canvasContainer"
    v-loading="isLoading"
    :element-loading-text="progressText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="canvasContainer"
  >
    <div
      v-if="progress !== null && progress!=-1"
      class="progress"
    >
      <el-progress :percentage="progress" />
    </div>
    <div
      v-if="error !== null"
      class="error"
      :title="error"
    >
      <el-alert
        :title="error"
        type="error"
        show-icon
      />
    </div>
  </div>
</template>

<script>
import { DxfViewer } from 'dxf-viewer';
import * as three from 'three';
import DxfViewerWorker from './DxfViewerWorker';

/** Events: all DxfViewer supported events (see DxfViewer.Subscribe()), prefixed with "dxf-". */
export default {
  name: 'DxfViewer',

  props: {
    dxfUrl: {
      type: String,
      default: null
    },
    /** List of font URLs. Files should have TTF format. Fonts are used in the specified order,
         * each one is checked until necessary glyph is found. Text is not rendered if fonts are not
         * specified.
         */
    fonts: {
      type: Array,
      default: null
    },
    options: {
      type: Object,
      default () {
        return {
          clearColor: new three.Color('#fff'),
          autoResize: true,
          colorCorrection: true
        };
      }
    }
  },

  data () {
    return {
      isLoading: false,
      progress: null,
      progressText: null,
      curProgressPhase: null,
      error: null
    };
  },

  methods: {
    async Load (url) {
      this.isLoading = true;
      this.error = null;
      try {
        await this.dxfViewer.Load({
          url,
          fonts: this.fonts,
          progressCbk: this._OnProgress.bind(this),
          workerFactory: DxfViewerWorker
        });
      } catch (error) {
        console.warn(error);
        this.error = error.toString();
      } finally {
        this.isLoading = false;
        this.progressText = null;
        this.progress = null;
        this.curProgressPhase = null;
      }
    },

    /** @return {DxfViewer} */
    GetViewer () {
      return this.dxfViewer;
    },

    _OnProgress (phase, size, totalSize) {
      if (phase !== this.curProgressPhase) {
        switch (phase) {
          case 'font':
            this.progressText = 'Fetching fonts...';
            break;
          case 'fetch':
            this.progressText = 'Fetching file...';
            break;
          case 'parse':
            this.progressText = 'Parsing file...';
            break;
          case 'prepare':
            this.progressText = 'Preparing rendering data...';
            break;
        }
        this.curProgressPhase = phase;
      }
      if (totalSize === null) {
        this.progress = -1;
      } else {
        this.progress = size / totalSize * 100;
      }
    }
  },

  watch: {
    async dxfUrl (dxfUrl) {
      if (dxfUrl !== null) {
        await this.Load(dxfUrl);
      } else {
        this.dxfViewer.Clear();
        this.error = null;
        this.isLoading = false;
        this.progress = null;
      }
    }
  },

  mounted () {
    this.dxfViewer = new DxfViewer(this.$refs.canvasContainer, this.options);
    const Subscribe = eventName => {
      this.dxfViewer.Subscribe(eventName, e => this.$emit('dxf-' + eventName, e));
    };
    for (const eventName of ['loaded', 'cleared', 'destroyed', 'resized', 'pointerdown',
      'pointerup', 'viewChanged', 'message']) {
      Subscribe(eventName);
    }
  },

  destroyed () {
    this.dxfViewer.Destroy();
    this.dxfViewer = null;
  }
};
</script>

<style lang="scss" scoped>

.canvasContainer {
  position: relative;
  width: 100%;
  min-width: 100px;
  height: 100%;
  min-height: 100px;

  .progress {
    position: absolute;
    z-index: 20;
    width: 90%;
    margin: 20px 5%;

    .progressText {
      margin: 10px 20px;
      font-size: 14px;
      color: #262d33;
      text-align: center;
    }
  }

  .error {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding: 30px;

    img {
      width: 24px;
      height: 24px;
      margin: 4px;
      vertical-align: middle;
    }
  }
}

</style>
